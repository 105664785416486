import React, { useEffect } from 'react';
import Gestureflag from '..';
import waveform from '@/Assets/icons/waveform.svg';
import volumeUp from '@/Assets/icons/volume-up.svg';
import { useGestureConfirmationStore } from '@/Models/useGestureConfirmationStore';
import { Gestures } from '@/Models/useDetectionStore';
import { KeyboardDistribution, KeyboardInputMode, useGestureKeyboardStore } from '@/Models/useGestureKeyboardStore';
import { KeyboardGestureEvent } from '@/Models/Constans';
import { useTextToSpeech } from '@/Services/useTextToSpeech';
import useComputeScores from '@/Xomponents/Detector/hooks/useComputeScores';
import './Speak.css';

const Speak: React.FC = function () {
  const { confirmation, config } = useGestureConfirmationStore();
  const {
    config: { keyboardInputMode, keyboardDistribution },
  } = useGestureKeyboardStore();

  const { idle } = useComputeScores();

  const { currentGesture, phrase } = useGestureKeyboardStore();
  const { isSynthesizing, handleSynthesis } = useTextToSpeech();

  useEffect(() => {
    if (!isSynthesizing) {
      if (keyboardDistribution === KeyboardDistribution.VERTICAL) {
        if (currentGesture?.gesture === KeyboardGestureEvent.LOOK_IDLE) {
          console.log('[STATUS] LOOK_IDLE');
          handleSynthesis(phrase);
        }
      } else if (keyboardDistribution === KeyboardDistribution.HORIZONTAL) {
        if (currentGesture?.gesture === KeyboardGestureEvent.LOOK_UP) {
          console.log('[STATUS] LOOK_UP');
          handleSynthesis(phrase);
        }
      }
    }
  }, [currentGesture]);

  const handleClick = () => {
    if (keyboardInputMode === KeyboardInputMode.POINTER) {
      handleSynthesis(phrase);
    }
  };

  const startTime = keyboardDistribution === KeyboardDistribution.HORIZONTAL ? confirmation.startTime[Gestures.lookUpScore] : confirmation.startTime[Gestures.lookIdleScore];
  const timeout = config.timeout[keyboardDistribution === KeyboardDistribution.HORIZONTAL ? Gestures.lookUpScore : Gestures.lookIdleScore];
  const running = isSynthesizing || (keyboardDistribution === KeyboardDistribution.HORIZONTAL && !idle.lookUpScore) || (keyboardDistribution === KeyboardDistribution.VERTICAL && !idle.lookIdleScore);
  return (
    <div className={`speak-wrapper${running ? ' running' : ''} ${keyboardDistribution === KeyboardDistribution.VERTICAL ? 'vertical' : ''}`} onClick={handleClick}>
      <Gestureflag svgUrl={isSynthesizing ? volumeUp : waveform} startTime={startTime} timeout={timeout} fill={isSynthesizing} />
      <div className="speak-text">PRONUNCIAR</div>
      {/* <div className="debug-info">
        <p>Start Time: {startTime}</p>
        <p>Timeout: {timeout}</p>
        <p>KD: {keyboardDistribution}</p>
      </div> */}
    </div>
  );
};

export default Speak;
