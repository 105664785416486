import axios from 'axios';
import IUser from '../Models/Types';

export const API_URL = process.env.REACT_APP_API;

const axiosInstance = axios.create({
  baseURL: `https://${API_URL}`,
});

export const getAxiosInstance = () => {
  return axiosInstance;
};

export const getAuthToken = () => {
  const userToken = localStorage.getItem('user');
  if (userToken) return { authToken: userToken };
  return { authToken: null };
};

const storeUser = ({ authToken, settings, birthdate, email, id, name, role }: IUser) => {
  localStorage.setItem(
    'user',
    JSON.stringify({
      authToken,
      settings,
      birthdate,
      email,
      id,
      name,
      role,
    })
  );
};

export const login = async (email: string, password: string) => {
  const { data } = await axiosInstance.post('/user/login', {
    email,
    password,
  });

  storeUser(data);
  return data;
};
