import React from 'react';
import { COMMANDS, KEY_LIST, LEFT_COMMANDS, RIGHT_COMMANDS } from '@/Models/Constans';
import { useGestureKeyboardStore } from '@/Models/useGestureKeyboardStore';

import './PointerKeyboard.css';
import { useTextToSpeech } from '@/Services/useTextToSpeech';
import PhraseContainer from '../PhraseContainer/PhraseContainer';

export const PointerKeyboard: React.FC = () => {
  const { pickKey, phrase } = useGestureKeyboardStore();
  const { handleSynthesis } = useTextToSpeech();
  const handleClick = (key: string) => {
    console.log(key);

    if (key === COMMANDS.SPEAK) {
      console.log('speak');
      handleSynthesis(phrase);
    } else pickKey(key);
  };

  return (
    <div id="pointer-keyboard">
      <PhraseContainer />

      <div className="keyboard-content">
        <div className="keyboard-keys">
          {KEY_LIST.map(key => (
            <div key={key} className="keyboard-key char" onClick={() => handleClick(key)}>
              {key}
            </div>
          ))}

          {LEFT_COMMANDS.map(command => (
            <div key={command} className="keyboard-key word" onClick={() => handleClick(command)}>
              {command}
            </div>
          ))}
          {RIGHT_COMMANDS.map(command => (
            <div key={command} className="keyboard-key word" onClick={() => handleClick(command)}>
              {command}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
