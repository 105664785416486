import React, { useEffect } from 'react';
import { KeyboardGestureEvent } from '@/Models/Constans';
import { useGestureKeyboardStore } from '@/Models/useGestureKeyboardStore';

import './EyeKeyboard.css';
import { Gestures } from '@/Models/useDetectionStore';
import Check from '../GestureFlag/Check/Check';
import useDetectionStore from '@/Models/useDetectionStore';
import { Detector } from '../Detector/Detector';
import EventCreator from '../EventCreator/EventCreator';
import PhraseContainer from '../PhraseContainer/PhraseContainer';

export const EyeKeyboard: React.FC = () => {
  const { left, right, currentGesture, init, pick } = useGestureKeyboardStore();

  const {
    gestures: { scores },
    rangeFiltering: { onRange },
    config: {
      gestureScores: { idleThreshold },
    },
  } = useDetectionStore();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (currentGesture?.gesture === KeyboardGestureEvent.LOOK_LEFT) {
      pickLeft();
    } else if (currentGesture?.gesture === KeyboardGestureEvent.LOOK_RIGHT) {
      pickRight();
    }
  }, [currentGesture]);

  /**
   * Selecciona las teclas y comandos del lado izquierdo.
   */
  const pickLeft = () => {
    pick(left.keys, left.commands);
  };

  /**
   * Selecciona las teclas y comandos del lado derecho.
   */
  const pickRight = () => {
    pick(right.keys, right.commands);
  };

  /**
   * Resuelve las clases CSS para el lado izquierdo.
   * @returns Las clases CSS correspondientes.
   */
  const resolveLeftSideClasses = () => {
    return scores.lookRightScore > idleThreshold || !onRange ? ' idle' : scores.lookLeftScore > idleThreshold ? ' active' : '';
  };

  /**
   * Resuelve las clases CSS para el lado derecho.
   * @returns Las clases CSS correspondientes.
   */
  const resolveRightSideClasses = () => {
    return scores.lookLeftScore > idleThreshold || !onRange ? ' idle' : scores.lookRightScore > idleThreshold ? ' active' : '';
  };

  const reversedRightSideKeys = [...right.keys];
  // const rightSideKeys = right.commands.concat(reversedRightSideKeys);

  const leftSideKeys = left.commands.concat(left.keys);
  const rightSideKeys = right.commands.concat(reversedRightSideKeys);

  const resolveKeyClasses = (key: string) => {
    return 'keyboard-key' + (key.length == 1 ? ' char' : '');
  };

  return (
    <div id="gesture-keyboard">
      <PhraseContainer />
      <div className="keyboard-content">
        <div className={'keyboard-sections'}>
          <div className={'keyboard-section left' + resolveLeftSideClasses()}>
            <div className="keyboard-keys">
              <Check gesture={Gestures.lookLeftScore} />
              {leftSideKeys.map((key: string) => (
                <div key={key} className={resolveKeyClasses(key)}>
                  {key}
                </div>
              ))}
            </div>
          </div>
          <div className="keyboard-section-separator">
            <EventCreator />
            <Detector />
          </div>
          <div className={'keyboard-section right' + resolveRightSideClasses()}>
            <div className="keyboard-keys">
              <Check gesture={Gestures.lookRightScore} />
              {rightSideKeys.map((key: string) => (
                <div key={key} className={resolveKeyClasses(key)}>
                  <span>{key}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
