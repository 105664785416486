import { BoundingBox, DrawingUtils, FaceLandmarker, FaceLandmarkerOptions, FaceLandmarkerResult } from '@mediapipe/tasks-vision';
import { create } from 'zustand';

export enum EyeGestures {
  EyeBlinkLeft = 'eyeBlinkLeft',
  EyeBlinkRight = 'eyeBlinkRight',
  EyeLookDownLeft = 'eyeLookDownLeft',
  EyeLookDownRight = 'eyeLookDownRight',
  EyeLookInLeft = 'eyeLookInLeft',
  EyeLookInRight = 'eyeLookInRight',
  EyeLookOutLeft = 'eyeLookOutLeft',
  EyeLookOutRight = 'eyeLookOutRight',
  EyeLookUpLeft = 'eyeLookUpLeft',
  EyeLookUpRight = 'eyeLookUpRight',
  EyeSquintLeft = 'eyeSquintLeft',
  EyeSquintRight = 'eyeSquintRight',

  Neutral = '_neutral',
}

export enum Gestures {
  lookUpScore = 'lookUpScore',
  lookDownScore = 'lookDownScore',
  lookLeftScore = 'lookLeftScore',
  lookRightScore = 'lookRightScore',
  lookIdleScore = 'lookIdleScore',
}

export type TGesstureScores = {
  [Gestures.lookUpScore]: number;
  [Gestures.lookDownScore]: number;
  [Gestures.lookLeftScore]: number;
  [Gestures.lookRightScore]: number;
  [Gestures.lookIdleScore]: number;
  timestamp: string; // Utilizar formato ISO 8601 para el timestamp: "YYYY-MM-DDTHH:mm:ss.sssZ"
};

export type TGestureScoresFlag = {
  [Gestures.lookUpScore]: boolean;
  [Gestures.lookDownScore]: boolean;
  [Gestures.lookLeftScore]: boolean;
  [Gestures.lookRightScore]: boolean;
  [Gestures.lookIdleScore]: boolean;
};

export const emptyGestureScoresFlag: TGestureScoresFlag = {
  [Gestures.lookUpScore]: false,
  [Gestures.lookDownScore]: false,
  [Gestures.lookLeftScore]: false,
  [Gestures.lookRightScore]: false,
  [Gestures.lookIdleScore]: false,
};

export enum RunningMode {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
}

export type TDetectionConfig = {
  faceLandMark: {
    active: boolean;
    faceLandmarkerOptions: FaceLandmarkerOptions;
    wasmFileBasePath: string;
  };
  rangeFiltering: {
    active: boolean;
    faceWidthMin: number;
    faceWidthMax: number;
    faceCentered: boolean;
  };
  gestureScores: {
    idleThreshold: number;
    activeThreshold: number;
    verticalActiveThreshold: number;
    upScoreThreshold: number;
    downScoreThreshold: number;
    historyDepth: number;
  };
  videoSource: {
    active: boolean;
    videoUrl: string;
  };
};

export const emptyGestureScores: TGesstureScores = {
  lookUpScore: 0,
  lookDownScore: 0,
  lookLeftScore: 0,
  lookRightScore: 0,
  lookIdleScore: 0,
  timestamp: '', // Utilizar formato ISO 8601 para el timestamp: "YYYY-MM-DDTHH:mm:ss.sssZ"
};

interface IDetectionStore {
  rangeFiltering: {
    boundingBox: BoundingBox | undefined;
    onRange: boolean;
  };
  faceLandMark: {
    detector: FaceLandmarker | undefined;
    complete: boolean;
    result: FaceLandmarkerResult | undefined;
    video: HTMLVideoElement | undefined;
    drawingUtils: DrawingUtils | undefined;
  };
  gestures: {
    scores: TGesstureScores;
    idle: TGestureScoresFlag;
    active: TGestureScoresFlag;
    last: TGesstureScores;
  };
  onRange: boolean;
  paused: boolean;
  config: TDetectionConfig;
  setVideoSourceActive: (active: boolean) => void;
  setRangeFilter: (boundingBox: BoundingBox | undefined, onRange: boolean) => void;
  setFaceLandMarkerDetector: (detector: FaceLandmarker) => void;
  setFaceLandMarkerDetectorVideo: (video: HTMLVideoElement) => void;
  setFaceLandMarkerDetectorDrawingUtils: (drawingUtils: DrawingUtils) => void;
  setFaceLandMarkerDetectorComplete: (complete: boolean) => void;
  setFaceLandMarkerDetectorResults: (result: FaceLandmarkerResult) => void;
  setGestureScores: (scores: TGesstureScores, last: TGesstureScores, idle: TGestureScoresFlag, active: TGestureScoresFlag) => void;
  setPaused: (paused: boolean) => void;
}

const useDetectionStore = create<IDetectionStore>(set => ({
  rangeFiltering: {
    boundingBox: undefined,
    onRange: false,
  },
  faceLandMark: {
    result: undefined,
    complete: false,
    detector: undefined,
    video: undefined,
    drawingUtils: undefined,
  },
  gestures: {
    scores: {
      ...emptyGestureScores,
    },
    idle: {
      ...emptyGestureScoresFlag,
    },
    active: {
      ...emptyGestureScoresFlag,
    },
    last: {
      ...emptyGestureScores,
    },
  },

  config: {
    faceLandMark: {
      active: false,
      faceLandmarkerOptions: {
        baseOptions: {
          // modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
          modelAssetPath: `wasm/face_landmarker.task`,

          delegate: 'GPU',
        },
        outputFaceBlendshapes: true,
        runningMode: RunningMode.VIDEO,
        numFaces: 1,
      },
      // wasmFileBasePath: 'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm',
      wasmFileBasePath: 'wasm',
    },
    rangeFiltering: {
      active: false,
      faceWidthMin: 0.2,
      faceWidthMax: 0.6,
      faceCentered: false,
    },
    gestureScores: {
      idleThreshold: 0.3,
      activeThreshold: 0.6,
      verticalActiveThreshold: 0.5,
      upScoreThreshold: 0.6,
      downScoreThreshold: 0.6,
      historyDepth: 8,
    },
    videoSource: {
      active: false,
      videoUrl: 'prueba.mp4',
    },
  },
  onRange: false,
  paused: false,
  setVideoSourceActive: (active: boolean) =>
    set(state => ({
      ...state,
      config: {
        ...state.config,
        videoSource: {
          ...state.config.videoSource,
          active,
        },
      },
    })),
  setRangeFilter: (boundingBox: BoundingBox | undefined, onRange: boolean) =>
    set(state => ({
      ...state,
      rangeFiltering: {
        ...state.rangeFiltering,
        boundingBox,
        onRange,
      },
    })),
  setFaceLandMarkerDetector: (detector: FaceLandmarker) =>
    set(state => ({
      ...state,
      faceLandMark: {
        ...state.faceLandMark,
        detector,
      },
    })),
  setFaceLandMarkerDetectorVideo: (video: HTMLVideoElement) =>
    set(state => ({
      ...state,
      faceLandMark: {
        ...state.faceLandMark,
        video,
      },
    })),
  setFaceLandMarkerDetectorDrawingUtils: (drawingUtils: DrawingUtils) =>
    set(state => ({
      ...state,
      faceLandMark: {
        ...state.faceLandMark,
        drawingUtils,
      },
    })),
  setFaceLandMarkerDetectorComplete: (complete: boolean) =>
    set(state => ({
      ...state,
      faceLandMark: {
        ...state.faceLandMark,
        complete,
      },
    })),
  setFaceLandMarkerDetectorResults: (result: FaceLandmarkerResult) =>
    set(state => ({
      ...state,
      faceLandMark: {
        ...state.faceLandMark,
        result,
      },
    })),
  setGestureScores: (scores: TGesstureScores, last: TGesstureScores, idle: TGestureScoresFlag, active: TGestureScoresFlag) =>
    set(state => ({
      ...state,
      gestures: {
        scores,
        idle,
        active,
        last,
      },
    })),
  setPaused: paused => set(state => ({ ...state, paused })),
}));

export default useDetectionStore;
