import React, { useEffect } from 'react';
import { KeyboardGestureEvent } from '@/Models/Constans';
import { useGestureKeyboardStore } from '@/Models/useGestureKeyboardStore';

import './VerticalEyeKeyboard.css';
import { Gestures } from '@/Models/useDetectionStore';
import Check from '../GestureFlag/Check/Check';
import useDetectionStore from '@/Models/useDetectionStore';
import { Detector } from '../Detector/Detector';
import EventCreator from '../EventCreator/EventCreator';
import PhraseContainer from '../PhraseContainer/PhraseContainer';
import Speak from '../GestureFlag/Speak/Speak';

export const VerticalEyeKeyboard: React.FC = () => {
  const { left, right, currentGesture, init, pick } = useGestureKeyboardStore();

  const {
    gestures: { scores },
    rangeFiltering: { onRange },
    config: {
      gestureScores: { idleThreshold },
    },
  } = useDetectionStore();

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (currentGesture?.gesture === KeyboardGestureEvent.LOOK_UP) {
      pickTop();
    } else if (currentGesture?.gesture === KeyboardGestureEvent.LOOK_DOWN) {
      pickBottom();
    }
  }, [currentGesture]);

  /**
   * Selecciona las teclas y comandos del lado izquierdo.
   */
  const pickTop = () => {
    pick(left.keys, left.commands);
  };

  /**
   * Selecciona las teclas y comandos del lado derecho.
   */
  const pickBottom = () => {
    pick(right.keys, right.commands);
  };

  /**
   * Resuelve las clases CSS para el lado izquierdo.
   * @returns Las clases CSS correspondientes.
   */
  const resolveLeftSideClasses = () => {
    return scores.lookDownScore > idleThreshold || !onRange ? ' idle' : scores.lookUpScore > idleThreshold ? ' active' : '';
  };

  /**
   * Resuelve las clases CSS para el lado derecho.
   * @returns Las clases CSS correspondientes.
   */
  const resolveRightSideClasses = () => {
    return scores.lookLeftScore > idleThreshold || !onRange ? ' idle' : scores.lookDownScore > idleThreshold ? ' active' : '';
  };

  const reversedRightSideKeys = [...right.keys];
  // const botSideKeys = right.commands.concat(reversedRightSideKeys);

  const topSideKeys = left.commands.concat(left.keys);
  const bottomSideKeys = right.commands.concat(reversedRightSideKeys);

  const resolveKeyClasses = (key: string) => {
    return 'keyboard-key' + (key.length == 1 ? ' char' : '');
  };

  return (
    <div id="vertical-keyboard">
      <div className="keyboard-content">
        <div className={'keyboard-sections'}>
          <div className={'keyboard-section top' + resolveLeftSideClasses()}>
            <div className="keyboard-keys">
              <Check gesture={Gestures.lookUpScore} />
              {topSideKeys.map((key: string) => (
                <div key={key} className={resolveKeyClasses(key)}>
                  {key}
                </div>
              ))}
            </div>
          </div>
          <div className="keyboard-section-separator">
            <EventCreator />
            {/* <Detector /> */}
            <PhraseContainer />
            <Speak />
          </div>
          <div className={'keyboard-section bottom' + resolveRightSideClasses()}>
            <div className="keyboard-keys">
              <Check gesture={Gestures.lookDownScore} />
              {bottomSideKeys.map((key: string) => (
                <div key={key} className={resolveKeyClasses(key)}>
                  <span>{key}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
