import { KeyboardDistribution, KeyboardInputMode, useGestureKeyboardStore } from './useGestureKeyboardStore';
import { persist, createJSONStorage } from 'zustand/middleware';
import { KeyboardGestureEvent } from './Constans';
import { IUserSettings } from './Types';
import { create } from 'zustand';
import IUser from './Types';

const initialUserState: IUser = {
  authToken: '',
  role: '',
  name: '',
  birthdate: '',
  id: '',
  settings: {
    smartGestureKeyboard: {
      keyboardDistribution: KeyboardDistribution.HORIZONTAL,
      keyboardInputMode: KeyboardInputMode.UNSET,
      keyboardSpeakGesture: KeyboardGestureEvent.LOOK_RIGHT,
    },
  },
};

const resetAllotherStores = () => {
  useGestureKeyboardStore.getState().restore();
};

const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen().catch(e => console.error(e));
  }
};

const releaseWebcam = () => {
  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(stream => {
        console.log('[STATUS] releaseWebcam', stream);
        stream.getTracks().forEach(track => track.stop());
      })
      .catch(err => console.error('Error releasing webcam:', err));
  }
};

// const resetAnimationFrame = () => {
//     if (animationFrameRef.current) {
//         window.cancelAnimationFrame(animationFrameRef.current);
//     }
// }

const rehydrateState = async (): Promise<IUser | null> => {
  const storedState = JSON.parse(localStorage.getItem('user-session-storage') || '{}');
  if (storedState.state && storedState.state.user) {
    console.log('[STATUS] rehydrateState', storedState);
    return storedState.state.user;
  }

  console.log('[STATUS] rehydrateState nothing to do', storedState);
  return null;
};

interface UserSessionState {
  user: IUser | null;
  isAuthenticated: boolean;
  getAuthToken: () => string;
  setUser: (user: IUser | null) => void;
  updateUserSettings: (settings: Partial<IUserSettings>) => void;
  logout: () => void;
  rehydrateState: () => Promise<void>;
}

const useUserSessionStore = create<UserSessionState>()(
  persist(
    (set, get) => ({
      user: { ...initialUserState },
      isAuthenticated: false,
      setUser: user => {
        set({
          user,
          isAuthenticated: !!user,
        });
      },
      getAuthToken: () => {
        const user = get().user;
        return user?.authToken || '';
      },
      updateUserSettings: settings => {
        const currentUser = get().user;
        if (currentUser) {
          set({
            user: {
              ...currentUser,
              settings: {
                ...currentUser.settings,
                ...settings,
              },
            },
          });
        }
      },
      logout: () => {
        set({
          user: null,
          isAuthenticated: false,
        });
        resetAllotherStores();
        exitFullscreen();
        localStorage.removeItem('user-session-storage');
        releaseWebcam();
        //refresh window
        window.location.reload();
      },
      rehydrateState: async () => {
        const user = await rehydrateState();
        if (user) {
          set({
            user,
            isAuthenticated: true,
          });
        }
      },
    }),
    {
      name: 'user-session-storage',
      storage: createJSONStorage(() => localStorage),
      skipHydration: true,
    }
  )
);

export default useUserSessionStore;
