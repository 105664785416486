import React, { useEffect, useState } from 'react';
import useDetectionStore from '@/Models/useDetectionStore';
import GestureKeyboard from '@/Xomponents/GestureKeyboard';
import FullScreenButton from './FullScreenButton/FullScreenButton';
import LandscapeMessage from '@/Xomponents/LandscapeMessage';
import Speak from '@/Xomponents/GestureFlag/Speak/Speak';
import LogoutButton from '@/Xomponents/LogoutButton/LogoutButton';
import Loader from '@/Xomponents/Loader/Loader';
// import WordSuggestion from '@/Xomponents/WordSuggestion/WordSuggestion';
import './Home.css';
import PhraseContainer from '@/Xomponents/PhraseContainer/PhraseContainer';
// import GestureCheck from '@/Xomponents/Detector/GestureCheck/GestureCheck';
// import EventsBar from '@/Xomponents/EventsBar/EventsBar';
import { KeyboardDistribution, KeyboardInputMode, useGestureKeyboardStore } from '@/Models/useGestureKeyboardStore';
import { Detector } from '@/Xomponents/Detector/Detector';
// import Suggest from '@/Xomponents/GestureFlag/Suggest/Suggest';

const Home: React.FC = () => {
  const {
    faceLandMark: { complete: completeLandMarkSetup },
  } = useDetectionStore();

  const {
    config: { keyboardInputMode, keyboardDistribution },
  } = useGestureKeyboardStore();

  const [isLandscape, setIsLandscape] = useState<boolean>(window.innerWidth > window.innerHeight);

  useEffect(() => {
    const checkOrientation = () => {
      const isLandscapeNow = window.innerWidth > window.innerHeight;
      console.log('Is Landscape: ', isLandscapeNow);
      setIsLandscape(isLandscapeNow);
    };
    window.addEventListener('resize', checkOrientation);
    return () => {
      window.removeEventListener('resize', checkOrientation);
    };
  }, [completeLandMarkSetup, keyboardInputMode]);

  const isLoading = keyboardInputMode === KeyboardInputMode.EYE && !completeLandMarkSetup;

  return (
    <div className="home-container" style={{ position: 'relative' }}>
      {isLoading && <Loader />}
      {/* {!isLandscape && <LandscapeMessage />} */}
      <div id="homeMain">
        <div className="home-buttons">
          <FullScreenButton />
          {keyboardDistribution === KeyboardDistribution.VERTICAL ? <Detector /> : <Speak />}
          <LogoutButton />
        </div>
        {/* <PhraseContainer /> */}
        <GestureKeyboard />
        {/* <EventsBar /> */}
        {/* <WordSuggestion /> */}
        {/* <Suggest /> */}
      </div>
    </div>
  );
};

export default Home;
