import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import useUserSessionStore from './Models/useUserSessionStore';
import NotFoundPage from './Xomponents/NotFound/NotFoundPage';
import Home from './Sections/Home/Home';
import Login from './Sections/Login/Login';
import FloatingButton from './Xomponents/FloatingButton/FloatingButton';
import { useEffect } from 'react';
import './App.css';

function App() {
  const { isAuthenticated, rehydrateState } = useUserSessionStore();
  console.log('isAuthenticated', isAuthenticated);

  useEffect(() => {
    rehydrateState();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={!isAuthenticated ? <Login /> : <Navigate replace to="/home" />} />
        <Route
          path="/home"
          element={
            isAuthenticated ? (
              <>
                <Home />
                {/* <FloatingButton /> */}
              </>
            ) : (
              <Navigate replace to="/login" />
            )
          }
        />
        <Route path="/" element={<Navigate replace to={isAuthenticated ? '/home' : '/login'} />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
