import React, { useRef, useEffect, useState, useCallback } from 'react';
import './Detector.css';
import Silhouette from '@/Xomponents/Silhouette/Silhouette';
import useComputeScores from './hooks/useComputeScores';
import useDetectionStore from '@/Models/useDetectionStore';
import { DrawingUtils, FaceLandmarker, NormalizedLandmark } from '@mediapipe/tasks-vision';
import useRangeFiltering from './hooks/useRangeFiltering';
import useFaceLandMarker from '@/Services/useFaceLandMarker';
import { KeyboardInputMode, useGestureKeyboardStore } from '@/Models/useGestureKeyboardStore';
import useUserSessionStore from '@/Models/useUserSessionStore';
import Webcam from 'react-webcam';

export const Detector: React.FC = () => {
  const {
    faceLandMark: { detector, result, drawingUtils, video },
    rangeFiltering: { onRange },
    setFaceLandMarkerDetectorVideo,
  } = useDetectionStore();

  const {
    config: { keyboardInputMode },
  } = useGestureKeyboardStore();
  const { isAuthenticated } = useUserSessionStore();

  const { canvasRef } = useFaceLandMarker();
  const webcamRef = useRef<Webcam>(null);

  useComputeScores();
  useRangeFiltering();

  useEffect(() => {
    // console.log('[STATUS] useEffect', keyboardInputMode, isAuthenticated, startWebcam, stopWebcam);
    const hasGetUserMedia = () => {
      return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
    };

    // console.log('[STATUS] webcamAccess', webcamRef);
    if (keyboardInputMode === KeyboardInputMode.EYE && isAuthenticated && hasGetUserMedia()) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(stream => {
          if (webcamRef.current && canvasRef.current) {
            const video = webcamRef.current.video;
            if (video) {
              video.srcObject = stream;
              setFaceLandMarkerDetectorVideo(video);
            }
          }
        })
        .catch(error => {
          console.error('Error al acceder a la cámara:', error);
        });
    } else {
      alert('getUserMedia no es soportado en tu navegador.');
    }
  }, [keyboardInputMode, isAuthenticated, webcamRef]);

  const [isWebcamActive, setIsWebcamActive] = useState(false);

  const startWebcam = useCallback(() => {
    setIsWebcamActive(true);
  }, []);

  const stopWebcam = useCallback(() => {
    setIsWebcamActive(false);
  }, []);

  useEffect(() => {
    if (keyboardInputMode === KeyboardInputMode.EYE && isAuthenticated) {
      startWebcam();
    } else {
      stopWebcam();
    }

    return () => {
      stopWebcam();
    };
  }, [keyboardInputMode, isAuthenticated, startWebcam, stopWebcam]);

  useEffect(() => {
    if (result?.faceLandmarks && drawingUtils && detector) {
      if (canvasRef.current && video) {
        canvasRef.current.width = video.videoWidth;
        canvasRef.current.height = video.videoHeight;
      }
      drawDetection(result.faceLandmarks, drawingUtils, detector, onRange);
    }
  }, [result, keyboardInputMode, drawingUtils, detector, video, onRange]);

  const drawDetection = (faceLandmarks: NormalizedLandmark[][], drawingUtils: DrawingUtils, faceLandmarker: FaceLandmarker, isOnRange: boolean) => {
    // console.log("entra a drawDetection",isOnRange)

    // const boundingBoxProperties = {
    //   color: isOnRange ? 'rgba(0,254,20,.2)' : 'rgba(254,20,20,.3)',
    //   fillColor: 'transparent',
    // };
    const baseColor = {
      color: isOnRange ? 'rgba(0,254,20,.2)' : 'rgba(254,20,20,.2)',
      lineWidth: 1,
    };

    const highLightColor = {
      color: isOnRange ? 'rgba(0,184,20,.4)' : 'rgba(184,20,20,.5)',
      lineWidth: 2,
    };

    const solidColor = {
      color: isOnRange ? 'rgba(0,254,20,.9)' : 'rgba(254,20,20,.9)',
      lineWidth: 2,
    };

    if (faceLandmarks && drawingUtils) {
      for (const landmarks of faceLandmarks) {
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_TESSELATION, baseColor);
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE, solidColor);
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_RIGHT_EYEBROW, baseColor);
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_LEFT_EYE, solidColor);
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_LEFT_EYEBROW, baseColor);
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_FACE_OVAL, solidColor);
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_LIPS, highLightColor);
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_RIGHT_IRIS, solidColor);
        drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_LEFT_IRIS, solidColor);
        // drawBoundingBox(landmarks)
      }
    }

    // console.log(boundingBox)
    // if (boundingBox !== undefined) {
    //   drawingUtils.drawBoundingBox(boundingBox, boundingBoxProperties);
    // }
  };

  const updateCountRef = useRef(0);
  const fpsRef = useRef(0);

  useEffect(() => {
    const interval = setInterval(() => {
      fpsRef.current = updateCountRef.current;
      updateCountRef.current = 0;
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    updateCountRef.current++;
  });

  // console.log('[STATUS] onRange', onRange, scores, active, idle);
  return (
    <div className="detectorContainer">
      <div className="centerColumn">
        <div className="camContainer">
          {isWebcamActive && (
            <Webcam
              ref={webcamRef}
              audio={false}
              autoPlay
              className="cameraFeed"
              style={{
                left: 0,
                right: 0,
                textAlign: 'center',
                zIndex: 9,
                width: '100%',
                height: 'auto',
                transform: 'scaleX(-1)',
                borderRadius: '10px',
                flex: 1,
              }}
            />
          )}

          <canvas
            ref={canvasRef}
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              textAlign: 'center',
              zIndex: 9,
              width: '100%',
              transform: 'scaleX(-1)',
              height: 'auto',
            }}
          />

          <Silhouette />
        </div>
      </div>
    </div>
  );
};
