import { useRef, useEffect, useCallback } from 'react';
import { FaceLandmarker, FilesetResolver, DrawingUtils } from '@mediapipe/tasks-vision';
import useDetectionStore, { TDetectionConfig } from '@/Models/useDetectionStore';
import { KeyboardInputMode, useGestureKeyboardStore } from '@/Models/useGestureKeyboardStore';
import useUserSessionStore from '@/Models/useUserSessionStore';

let detector: FaceLandmarker | null = null;

const loadModel = async (setFaceLandMarkerDetector: (detector: FaceLandmarker) => void, config: TDetectionConfig) => {
  if (detector === null) {
    const vision = await FilesetResolver.forVisionTasks(config.faceLandMark.wasmFileBasePath);
    detector = await FaceLandmarker.createFromOptions(vision, config.faceLandMark.faceLandmarkerOptions);
    setFaceLandMarkerDetector(detector);
  } else {
    setFaceLandMarkerDetector(detector);
  }
};

const useFaceLandMarker = () => {
  const {
    config,
    faceLandMark,
    setFaceLandMarkerDetector,
    setFaceLandMarkerDetectorComplete,
    setFaceLandMarkerDetectorVideo,
    setFaceLandMarkerDetectorResults,
    setFaceLandMarkerDetectorDrawingUtils,
  } = useDetectionStore();

  const {
    config: { keyboardInputMode },
  } = useGestureKeyboardStore();

  const webcamRef = useRef<HTMLVideoElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const lastVideoTimeRef = useRef(0);

  // const [lastVideoTime, setLastVideoTime] = useState(0);

  // Referencia mutable para almacenar el último valor de config.keyboardInputMode
  const keyboardInputModeRef = useRef(keyboardInputMode);

  const { isAuthenticated } = useUserSessionStore();
  const isAuthenticatedRef = useRef(isAuthenticated);
  // Actualizar la referencia cuando cambie config.keyboardInputMode
  const animationFrameRef = useRef<number | null>(null);

  useEffect(() => {
    if (!isAuthenticated) {
      if (animationFrameRef.current) {
        console.log('[STATUS] useFacelandMarker cancelAnimationFrame', animationFrameRef.current);
        window.cancelAnimationFrame(animationFrameRef.current);
      }
    }
  }, [isAuthenticated, animationFrameRef.current]);

  useEffect(() => {
    console.log('[STATUS] useFacelandMarker keyboardInputMode', keyboardInputMode, isAuthenticated);
    keyboardInputModeRef.current = keyboardInputMode;
    isAuthenticatedRef.current = isAuthenticated;
  }, [keyboardInputMode, isAuthenticated]);

  // load model
  useEffect(() => {
    try {
      loadModel(setFaceLandMarkerDetector, config);
      console.log('[STATUS] loadModel');
    } catch (error) {
      console.error('[ERROR] loadModel', error);
    }
  }, []);

  useEffect(() => {
    const context = canvasRef?.current?.getContext('2d');
    if (context) {
      setFaceLandMarkerDetectorDrawingUtils(new DrawingUtils(context));
    }
    // console.log('[STATUS] drawigUtils complete');
  }, [canvasRef]);

  useEffect(() => {
    // console.log('[STATUS] useFaceLandMarker', config.videoSource.active);
    const hasGetUserMedia = () => {
      return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
    };

    const webcamAccess = () => {
      console.log('[STATUS] webcamAccess', webcamRef);
      if (hasGetUserMedia()) {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then(stream => {
            if (webcamRef.current && canvasRef.current) {
              const video = webcamRef.current;
              video.srcObject = stream;
              setFaceLandMarkerDetectorVideo(video);
            }
          })
          .catch(error => {
            console.error('Error al acceder a la cámara:', error);
          });
      } else {
        alert('getUserMedia no es soportado en tu navegador.');
      }
    };

    const videoAccess = () => {
      try {
        if (videoRef.current) {
          setFaceLandMarkerDetectorVideo(videoRef.current);
        }
        // console.log('[STATUS] useFacelandMarker videoAccess');
      } catch (error) {
        console.error('[ERROR] useFacelandMarker videoAccess', error);
      }
    };

    const { active } = config.videoSource;

    if (active) {
      videoAccess();
    } else {
      webcamAccess();
    }
  }, [webcamRef, videoRef, config.videoSource]);

  useEffect(() => {
    const { video, detector } = faceLandMark;
    // console.log('[STATUS] useFacelandMarker useEffect', keyboardInputMode, video, detector);
    if (video && detector) {
      setFaceLandMarkerDetectorComplete(true);
      if (keyboardInputMode === KeyboardInputMode.EYE) {
        predict();
      }
    }
  }, [faceLandMark.video, faceLandMark.detector, keyboardInputMode]);

  const predict = useCallback(() => {
    // console.log('[STATUS] useFacelandMarker predict', keyboardInputModeRef.current);
    if (!isAuthenticatedRef.current) {
      console.log('[STATUS] useFacelandMarker predict', 'isAuthenticatedRef.current', isAuthenticatedRef.current);
      return;
    } else {
      const { video, detector } = faceLandMark;
      try {
        const startTimeMs = performance.now();
        if (detector && video && lastVideoTimeRef.current !== video.currentTime) {
          // setLastVideoTime(video.currentTime);
          // console.log('[STATUS] useFacelandMarker predict', video.currentTime, lastVideoTimeRef.current,);
          lastVideoTimeRef.current = video.currentTime;
          const results = detector.detectForVideo(video, startTimeMs);
          setFaceLandMarkerDetectorResults(results);
        }
        //animationFrameRef.current = window.requestAnimationFrame(predict);
      } catch (error) {
        console.error('[STATUS] Error al detectar marcas faciales en el video:', error);
      }
      if (keyboardInputModeRef.current === KeyboardInputMode.EYE) {
        // console.log('[STATUS] useFacelandMarker requestAnimationFrame', video, detector, isAuthenticatedRef.current);
        animationFrameRef.current = window.requestAnimationFrame(predict);
      }
    }
  }, [faceLandMark.video, faceLandMark.detector, setFaceLandMarkerDetectorResults, keyboardInputModeRef.current, isAuthenticatedRef.current]);

  return { webcamRef, videoRef, canvasRef };
};

export default useFaceLandMarker;
