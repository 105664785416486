import React, { useState, useEffect } from 'react';

import enterFullScreen from '../../../Assets/icons/enterFullScreen.svg';
import exitFullScreen from '../../../Assets/icons/exitFullScreen.svg';
import './FullScreenButton.css';

const FullScreenButton: React.FC = () => {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(prev => !prev);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch(e => console.error(e));
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen().catch(e => console.error(e));
      }
    }
  };

  return (
    <button onClick={toggleFullScreen} className="btn fullScreenButton">
      <img className="icon" src={isFullScreen ? exitFullScreen : enterFullScreen} />
      <span className="label">{isFullScreen ? 'Salir Pantalla Completa' : ' Pantalla Completa'}</span>
    </button>
  );
};

export default FullScreenButton;
