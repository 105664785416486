import React from 'react';
import './LogoutButton.css';
import logoutIcon from '@/Assets/icons/logout-svgrepo-com.svg';

import useUserSessionStore from '@/Models/useUserSessionStore';

const LogoutButton: React.FC = () => {
  const { logout } = useUserSessionStore();

  const handleLogout = () => {
    logout();
  };

  return (
    <button onClick={handleLogout} className="logout-button">
      <img className="icon" src={logoutIcon} />
      <span>Cerrar Sesión</span>
    </button>
  );
};

export default LogoutButton;
