import { defaultKeyboardSettings, IKeyboardSettings, KeyboardDistribution, KeyboardInputMode, useGestureKeyboardStore } from '@/Models/useGestureKeyboardStore';
import { PointerKeyboard } from './pointerKeyboard';
import { EyeKeyboard } from './EyeKeybord';
import useUserSessionStore from '@/Models/useUserSessionStore';
// import useDetectionStore from '@/Models/useDetectionStore';
import { useEffect } from 'react';
import { VerticalEyeKeyboard } from './VerticalEyeKeyboard';

/**
 * Componente principal del teclado de gestos.
 * @returns El componente GestureKeyboard.
 */
const GestureKeyboard = () => {
  const { user } = useUserSessionStore();

  const {
    setSettings,
    config: { keyboardInputMode, keyboardDistribution },
  } = useGestureKeyboardStore();

  useEffect(() => {
    if (user?.settings?.smartGestureKeyboard) {
      console.log('settings to hidrate: ', user?.settings?.smartGestureKeyboard);
      setSettings(user.settings.smartGestureKeyboard as IKeyboardSettings);
    } else {
      console.log('settings to default: ', defaultKeyboardSettings);
      // setSettings({ ...defaultKeyboardSettings });
    }
  }, [user]);

  return keyboardInputMode === KeyboardInputMode.UNSET ? (
    <div>Loading...</div>
  ) : keyboardInputMode === KeyboardInputMode.POINTER ? (
    <PointerKeyboard />
  ) : KeyboardDistribution.VERTICAL === keyboardDistribution ? (
    <VerticalEyeKeyboard />
  ) : (
    <EyeKeyboard />
  );
};

export default GestureKeyboard;
