import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { login } from '../../Services/AuthService';
// import IUser from '../../Models/Types';
import './Login.css';
import logo from '../../Assets/images/logo.svg';
import LandscapeMessage from '../../Xomponents/LandscapeMessage';
import useUserSessionStore from '@/Models/useUserSessionStore';

const Login: React.FC = () => {
  const navigate: NavigateFunction = useNavigate();
  const { setUser } = useUserSessionStore(); // Get setCurrentUser from context

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [isLandscape, setIsLandscape] = useState<boolean>(window.innerWidth > window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('This field is required!').email('Invalid email address'),
    password: Yup.string().required('This field is required!'),
  });

  const handleLogin = async (formValue: { email: string; password: string }) => {
    const { email, password } = formValue;

    setMessage('');
    setLoading(true);

    try {
      const data = await login(email, password);
      setUser(data);
      navigate('/home');
    } catch (error: unknown) {
      const resMessage = error instanceof Error ? error.message : (error as { response?: { data?: { message?: string } } })?.response?.data?.message || 'An unknown error occurred';

      setMessage(resMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`loginContainer ${isLandscape ? 'landscape' : 'portrait'}`}>
      {/* {!isLandscape && <LandscapeMessage />} */}

      <div className="logo_login_wrapper">
        <img alt="logo" src={logo} className="logo_login" />
      </div>

      <div className="form-container">
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleLogin}>
          <Form>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <Field name="email" type="text" className="form-control" />
              <ErrorMessage name="email" component="div" className="alert alert-danger" />
            </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Field name="password" type="password" className="form-control" />
              <ErrorMessage name="password" component="div" className="alert alert-danger" />
            </div>

            <div className="form-group">
              <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                {loading && <span className="spinner-border spinner-border-sm"></span>}
                <span>Login</span>
              </button>
            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default Login;
