import { useState, useEffect } from 'react';
import { BoundingBox, FaceLandmarker, NormalizedLandmark } from '@mediapipe/tasks-vision';
import useDetectionStore from '@/Models/useDetectionStore';

const useRangeFiltering = () => {
  const {
    config,
    faceLandMark: { video, result },
    setRangeFilter,
  } = useDetectionStore();

  const [boundingBox, setBoundingBox] = useState<BoundingBox | undefined>(undefined);
  //   const [isOnRange, setIsOnRange] = useState(false);

  const updateFacelandmarks = (facelandmarks: NormalizedLandmark[]) => {
    try {
      if (facelandmarks && video) {
        // console.log("entra a updateFacelandmarks", video)

        const faceOvalLandmarks = facelandmarks.filter((point, index) => FaceLandmarker.FACE_LANDMARKS_FACE_OVAL.some(connection => connection.start === index || connection.end === index));

        let minX = Infinity,
          maxX = -Infinity,
          minY = Infinity,
          maxY = -Infinity;
        faceOvalLandmarks.forEach(point => {
          if (point.x < minX) minX = point.x;
          if (point.x > maxX) maxX = point.x;
          if (point.y < minY) minY = point.y;
          if (point.y > maxY) maxY = point.y;
        });

        const width = maxX - minX;
        const height = maxY - minY;

        const newBoundingBox = {
          originX: minX * video.videoWidth,
          originY: minY * video.videoHeight,
          width: width * video.videoWidth,
          height: height * video.videoHeight,
          angle: 0,
        };

        // console.log("entra a updateFacelandmarks",detectionConfig  )
        const isWidthInRange = newBoundingBox.width >= config.rangeFiltering.faceWidthMin * video.videoWidth && newBoundingBox.width <= config.rangeFiltering.faceWidthMax * video.videoWidth;
        setBoundingBox(newBoundingBox);
        setRangeFilter(newBoundingBox, isWidthInRange);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (result?.faceLandmarks[0]) updateFacelandmarks(result.faceLandmarks[0]);
  }, [result?.faceLandmarks[0]]);

  return { boundingBox, updateFacelandmarks };
};

export default useRangeFiltering;
